<template>
  <section v-if="data" class="o-seo-text">
    <div class="o-seo-text__container container">
      <h2 v-if="data.title" class="o-seo-text__title">{{ data.title }}</h2>
      <div v-html="data.text.value" class="o-seo-text__content"></div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';

// Composables
import useGetData from '~/composables/api/useGetData';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TOSeoText } from '~/components/O/Seo/Text/OSeoText.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Seo/Text/OSeoText.schema';

defineOptions({
  name: 'OSeoText',
});

const props = withDefaults(defineProps<IComponentProps<TOSeoText>>(), {
  viewName: 'OSeoText',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TOSeoText>();

const data = ref<TOSeoText>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
